<template>
  <div class="columns">
    <div class="column is-half">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            Gardening Tip: Catching up after a long time
          </p>
          <button
            class="card-header-icon"
            aria-label="show"
            @click="expanded = !expanded"
          >
            <span class="icon" v-if="expanded">
              <i class="fas fa-angle-up" aria-hidden="true"></i>
            </span>
            <span class="icon" v-else>
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </header>
        <transition name="fade">
          <div class="card-content" v-if="expanded">
            <div class="content has-text-left">
              <p>
                Sometimes you might be reminded of someone you used to be more
                connected with, but haven't talked to in a long time. It can
                feel awkward trying to think of what you would say to them after
                such a long time, but that doesn't mean your relationship is
                over forever.
              </p>
              <p>
                Keep in mind however, how great it is to reconnect with an old
                friend, and that is a feeling you can give to them. If you don't
                know what to say, try sending a message like this:
              </p>
              <blockquote>
                <em>
                  Hi [friend], It's been a long time! I was just thinking about
                  you the other day and wanted to reconnect. Here is a quick
                  breakdown of what I have been up
                  <ul>
                    <li>
                      (here you can add 2-3 bullet points of recent life
                      milestones or things that you think your friend might be
                      interested in.)
                    </li>
                  </ul>
                  I'd love to hear about what you are up to! Are you availabe
                  for a call or coffee sometime.
                  <p>Best,</p>
                  <p>
                    {{ user.displayName }}
                  </p>
                </em>
              </blockquote>
              <p>
                After you get a response back or reconnect, take some notes about what is going on in their life, any important names or details you might want to remember, and add reminders for yourself for any upcoming reasons you might want to reach back out.
                </p>
            </div>
          </div>
        </transition>
      </div>
      <br />
      <UpcomingReminders :data="relationships" />
    </div>
    <div class="column is-half">
      <div class="field is-grouped">
        <!-- <div class="control">
          <Search :data="relationships" />
        </div> -->
        <div class="control">
          <a class="button" @click="addNew">
            <span>Add New</span>
            <span class="icon">
              <i class="fas fa-user-plus"></i>
            </span>
          </a>
        </div>
      </div>

      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>
              First
              <a @click="handleSortClick('fname')">
                <span
                  v-if="reversed"
                  class="icon"
                  :class="{
                    'has-text-grey-light': sortedBy !== 'fname',
                    'has-text-black': sortedBy == 'fname',
                  }"
                >
                  <i class="fas fa-sort-alpha-down"></i>
                </span>
                <span
                  v-if="!reversed"
                  class="icon"
                  :class="{
                    'has-text-grey-light': sortedBy !== 'fname',
                    'has-text-black': sortedBy == 'fname',
                  }"
                >
                  <i class="fas fa-sort-alpha-down-alt"></i>
                </span>
              </a>
            </th>
            <th>
              Last
              <a @click="handleSortClick('lname')">
                <span
                  v-if="reversed"
                  class="icon"
                  :class="{
                    'has-text-grey-light': sortedBy !== 'lname',
                    'has-text-black': sortedBy == 'lname',
                  }"
                >
                  <i class="fas fa-sort-alpha-down"></i>
                </span>
                <span
                  v-if="!reversed"
                  class="icon"
                  :class="{
                    'has-text-grey-light': sortedBy !== 'lname',
                    'has-text-black': sortedBy == 'lname',
                  }"
                >
                  <i class="fas fa-sort-alpha-down-alt"></i>
                </span>
              </a>
            </th>
            <th>Last contacted</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="r in sortedRelationships" :key="r.id">
            <td>
              <router-link class="relationship" :to="'/relationship/' + r.id"
                >{{ r.fname }}
              </router-link>
            </td>
            <td>
              <router-link class="relationship" :to="'/relationship/' + r.id"
                >{{ r.lname }}
              </router-link>
            </td>
            <td>
              <router-link class="relationship" :to="'/relationship/' + r.id">
                {{
                  r.events &&
                  Object.values(r.events).filter((e) => e.type != "Reminder")
                    .length
                    ? Object.values(r.events)
                        .filter((e) => e.type != "Reminder")
                        .sort((a, b) => new Date(b.date) - new Date(a.date))[0]
                        .date
                    : ""
                }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { db, user } from '../components/firebase.js'
import { computed, onMounted, ref } from 'vue'
// import Search from '../components/Search.vue'
import UpcomingReminders from '../components/UpcomingReminders.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  components: {
    // Search,
    UpcomingReminders
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const expanded = ref(false)
    async function addNew () {
      const newDoc = await db
        .collection('users')
        .doc(user.value.uid)
        .collection('relationships')
        .add({})
      router.push(`/relationship/${newDoc.id}`)
    }
    const sortedRelationships = ref([])
    onMounted(() => {
      // update the last tended field for analytics
      db.collection('users').doc(user.value.uid).set(
        {
          lastTended: new Date()
        },
        { merge: true }
      )

      sortedRelationships.value = store.state.allRelationships
      sortedRelationships.value.sort((a, b) => {
        if (!a.fname) {
          return 1
        }
        if (!b.fname) {
          return -1
        }
        if (a.fname.toLowerCase() > b.fname.toLowerCase()) {
          return 1
        }
        return -1
      })
    })
    const sortedBy = ref('')
    const reversed = ref(true)
    function handleSortClick (field) {
      if (field === sortedBy.value) {
        reversed.value = !reversed.value
      }
      sortedBy.value = field
      sortedRelationships.value.sort((a, b) => {
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return reversed.value ? 1 : -1
        }
        return reversed.value ? -1 : 1
      })
    }

    return {
      user,
      relationships: computed(() => store.state.allRelationships),
      sortedRelationships,
      addNew,
      handleSortClick,
      sortedBy,
      reversed,
      expanded
    }
  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.relationship {
  display: block;
}
</style>
