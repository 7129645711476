<template>
  <div>
    <h2 class="title is-5">Upcoming Reminders</h2>
    <div v-for="(reminder, index) in upcomingReminders" :key="index" class="card">
      <div class="card-content">
        <div class="content">
          {{ reminder.reminder.text }}
        </div>
      </div>
      <footer class="card-footer">
        <!-- change to time tag? -->
        <router-link
          :to="`/relationship/${reminder.id}`"
          class="card-footer-item"
          >{{ reminder.name }}</router-link
        >
        <span
          class="card-footer-item"
          :class="{
            'has-text-danger': new Date(reminder.reminder.date) < new Date(),
          }"
          v-if="reminder.reminder.date"
          >{{ reminder.reminder.date }}</span
        >

        <a
          href="#"
          @click="markAsComplete(reminder.id, reminder.reminderKey)"
          class="card-footer-item"
        >
          <span class="icon"> <i class="fas fa-check"></i> </span
        ></a>
      </footer>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { db, user } from '../components/firebase.js'
import { getDate } from './utility.js'

export default {
  props: {
    data: Array
  },
  setup (props) {
    const localData = ref({})
    const upcomingReminders = ref([])
    watchEffect(() => {
      if (props.data) {
        localData.value = props.data
      }
    })
    watchEffect(() => {
      upcomingReminders.value = [].concat
        .apply(
          [],
          localData.value
            .filter(
              (f) =>
                f.events &&
                Object.values(f.events).some(
                  (e) => e.type === 'Reminder' && e.status !== 'Complete'
                )
            )
            .map((m) =>
              Object.keys(m.events)
                .filter((f) => m.events[f].type === 'Reminder')
                .map((r) => ({
                  reminder: m.events[r],
                  reminderKey: r,
                  id: m.id,
                  name: `${m.fname} ${m.lname}`
                }))
            )
        )
        .sort((a, b) => {
          return getDate(a.reminder.date) - getDate(b.reminder.date)
        })
    })
    function markAsComplete (relationshipId, key) {
      db.doc(`users/${user.value.uid}/relationships/${relationshipId}`).update({
        [`events.${key}.status`]: 'Complete'
      })
      console.log(upcomingReminders)
    }
    return {
      markAsComplete,
      localData,
      upcomingReminders
    }
  }
}
</script>
